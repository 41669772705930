import activity from './m-digital-resume.vue'; // re-export your activity

import { ActivitySchema, Role } from '../index';

export default activity;

export const componentRegistrationData: ActivitySchema = {
  name: 'Resume', // name of your activity
  icon: 'mdi-file-account', // mdi icon of choice
  description: 'Digital resume workshop for participants',
  status: true,
  setupRequired: true,
  styling: {
    borderColor: '#0077B5' // color of choice for your activity
  },
  fields: [
    {
      roles: [
        // who can see this field -- RECOMMENDED TO BE LEFT ALONE
        Role.Employer,
        Role.Participant
      ],
      name: 'm-digital-resume',
      type: 'molecule',
      position: 0, // this is the order in which your fields will be rendered
      preset: {},
      setup: {}, // this is your organizer data, what employers will setup
      value: {
        // this is your participant data, what will be filled in by students
        studentLinkedinProfile: ''
      }
    },
    {
      // this should *almost always* be here and represents your save button
      roles: [Role.Employer, Role.Participant],
      name: 'm-save-adk-only',
      type: 'molecule',
      position: 1, // traditionally this is always last
      preset: {},
      setup: {},
      value: {}
    }
  ]
};
