export enum Role {
  Employer = 'employer',
  Participant = 'participant'
}

export interface ActivitySchemaStyling {
  borderColor: string;
}

export interface Permission {
  roles: string;
  permissions_matrix: string[];
}

export interface FieldStyling {
  class?: string;
  prependIcon?: string;
  placeholder?: string;
  label?: string;
  hint?: string;
  outlined?: boolean;
  rounded?: boolean;
  wrapperClass?: string;
  small?: boolean;
  depressed?: boolean;
  counter?: number;
  'small-chips'?: boolean;
  'hide-details'?: boolean;
}

export interface Preset {
  [key: string]: any;
}

export interface EventClass {
  trigger: string;
  title: string;
  body: string;
  buttons: any[];
  component: string;
}

export interface Field {
  roles: Role[];
  type: string;
  title?: string;
  position: number;
  styling?: FieldStyling;
  name?: string;
  rules?: string[];
  model?: string;
  preset?: Preset;
  setup?: Preset;
  value?: Preset;
  action?: string;
  target?: string;
  event?: EventClass | string;
  items?: string[];
  searchInput?: string;
  multiple?: boolean;
  placeholder?: string;
  icon?: string;
  searchType?: string;
  label?: string;
  outlined?: boolean;
  rounded?: boolean;
  header?: string;
  min?: string;
  max?: string;
  ages?: string;
  ticks?: boolean;
  tickSize?: number;
}

export interface ProjectStyling {
  label: string;
  leftIcon: string;
  color: string;
  rounded: boolean;
  depressed: boolean;
}

export interface Project {
  roles: Role[];
  name?: string;
  action?: string;
  target?: string;
  type: string;
  styling?: ProjectStyling;
  position: number;
  title?: string;
  icon?: string;
}

export interface ObjectiveStyling {
  placeholder?: string;
  label: string;
  hint?: string;
  outlined: boolean;
  rounded: boolean;
  rightIcon?: string;
  small?: boolean;
  depressed?: boolean;
  icon?: string;
  leftIcon?: string;
}

export interface PresetField {
  roles: Role[];
  type: string;
  title: string;
  noSetup: boolean;
  position: number;
}

export interface Objective {
  roles: Role[];
  type: string;
  title?: string;
  icon?: string;
  position: number;
  name?: string;
  rules?: any[];
  styling?: ObjectiveStyling;
  action?: string;
  target?: string;
}

export interface BuildScope {
  objectives: Objective[];
  projects: Project[];
}

export interface ID {
  $oid: string;
}

export interface ActivitySchema {
  _id: ID;
  name: string;
  icon: string;
  description?: string;
  activityImage?: string;
  status: boolean;
  setupRequired: boolean;
  position?: number;
  fields: Field[];
  presetFields?: PresetField[];
  permissions?: Permission[];
  category?: string;
  styling?: ActivitySchemaStyling;
  preview?: boolean;
  buildScope?: BuildScope;
}
